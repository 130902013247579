.feedbackContainer {
  position: relative;
  height: 0;
  width: 0;
}

.incorrectFeedback {
  background-color: #25292e;
  border-radius: 8px;
  border-width: 0;
  font-size: 12px;
  height: 36px;
  justify-content: center;
  line-height: 1;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: white;
  opacity: 0;
  transform: opacity;
  transition: 0.2s;
  transition-timing-function: ease-in;
  font-size: 16px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: normal;
  margin-left: 8px;
  position: absolute;
  right: -8px;
  bottom: 30px;
  width: max-content;
}

.incorrectFeedback:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 8px;
  bottom: -8px;
  border: 6px solid;
  border-color: #25292e #25292e transparent transparent;
}

.showIncorrectFeedback {
  opacity: 1;
}

@media screen and (max-width: 639px) {
  .incorrectFeedback {
    bottom: 24px;
  }
}
