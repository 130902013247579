#tileArea {
  display: inline-flex;
  margin: 24px 0;
  position: relative;
}

.tile {
  height: 60px;
  width: 60px;
  display: inline-flex;
  margin: 4px;
  border: 1px solid #888888;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 24px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.tile:active {
  transform: scale(0.96);
}

.flip-container .flipper.flip {
  transform: rotateX(180deg);
  transition: transform 0.5s;
}

.flipper {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: transform 2s;
  transform-style: preserve-3d;
}

.front,
.back {
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transform: rotateX(0deg);
}

.back {
  transform: rotateX(180deg);
}

.emoji-tile:active {
  transform: none;
}

.active-solution-slot::after {
  content: "";
  width: 1px;
  height: 20px;
  background: black;
  margin-left: 0px;
  display: inline-block;
  animation: cursor-blink 1.25s steps(2) infinite;
}

.used-tile {
  color: white;
  opacity: 0.5;
  pointer-events: none;
}


@media screen and (max-width: 639px) {
  #tileArea .tile {
    height: 10.8vw;
    width: 8.5vw;
    font-size: 18px;
    margin: 2px;
  }

  #tileArea {
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 16px 0;
  }
}
