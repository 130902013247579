/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
  max-width: 600px;
}

/* The Close Button */
.close {
  color: #aaa;
  position: absolute;
  right: 24px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.rules-title {
  font-size: 24px;
  margin: auto;
  margin-top: 24px;
  width: 90%;
}

#yesterdayContent {
  margin-top: 24px;
}

.about-content {
  max-width: 90%;
  margin: auto;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 300;
}
