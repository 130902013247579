.button-menu {
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: center;
}

.emoji-button {
  padding: 0;
  border: none;
  background: none;
  font-size: 42px;
  margin: 0;
  margin-right: 4px;
}

#deleteButton {
  margin-left: 8px;
  margin-bottom: 2px;
}

#clearButton {
  margin-right: 12px;
  margin-bottom: 2px;
}

.show-next-button {
  margin-top: 16px !important;
}

.hints-used {
  text-align: center;
  margin-top: 12px;
}

.pauseButtonContainer {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 639px) {
  .emoji-button {
    font-size: 40px;
  }
}
