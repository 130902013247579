.share-content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goat-modal-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 12px;
}

.congrats-modal-button-row {
  margin-top: 8px;
}

#shareableContent {
  width: 200px;
  font-size: 18px;
  margin: auto;
}

.goat {
  font-size: 150px;
}

.goat-text {
  font-size: 24px;
  margin-bottom: 42px;
}

.nextAnigramContent {
  text-align: center;
}

#shareButton {
  margin-right: 12px;
}

#archiveButton {
  margin-right: 12px;
}

.badge {
  position: absolute;
  top: -16px;
  right: -10px;
  padding: 4px 6px;
  border-radius: 16px;
  background: red;
  color: white;
  font-size: 10px;
}

.newButtonContainer {
  display: inline;
  position: relative;
}
