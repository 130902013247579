.welcome {
  font-size: 32px;
  text-align: center;
  margin-top: 24px;
}

.welcomeSubtitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 16px;
}

.welcomeEmojis {
  font-size: 32px;
  text-align: center;
  margin-bottom: 16px;
}

.welcomeButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.welcomeButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 20px;
}

.noWelcomeMargin {
  margin-left: 0px;
}

.welcomeButton {
  margin-bottom: 4px;
}

.patreonButton {
  margin-top: 24px;
  margin-bottom: 4px;
}

.welcomeBody {
  max-width: 600px;
  margin: auto;
  padding: 0 24px;
}

.OrderUpButton {
  margin-top: 24px;
  margin-bottom: 4px;
}

.welcomeAnigramsCopy {
  margin-bottom: 4px;
  padding: 0px 24px;
  text-align: center;
  max-width: 600px;
}

@media screen and (max-width: 800px) {
  .welcomeButtonWrapper {
    flex-direction: column;
  }

  .welcomeButtonContainer {
    margin-left: 0px;
  }
}
